<template>
    <div></div>
  </template>
  
  <script setup>
  import { onMounted, ref } from "vue";
import { useGlobalStore } from "~/stores/global";
  
  const globalStore = useGlobalStore();
  const myLandbot = ref();
  
  const findWelcome = () => {
      let ifr = document.querySelector(".LandbotLivechat iframe");
      if (ifr) {
          let doc = ifr.contentDocument;
          let open = doc?.querySelector(".is-open.LivechatContent");
  
          if (open) {
              clearInterval(intervalId);
              console.log("OPENED");
  
              setTimeout(() => {
                  myLandbot.value?.sendMessage({
                      message: "default!",
                      payload: "default",
                      type: "hidden",
                  });
              }, 500);
  
              console.log("found", open);
          }
      }
  };
  
  let intervalId;
  
  const initLandbot = () => {
      var s = document.createElement("script");
      s.type = "module";
      s.async = true;
  
      s.addEventListener("load", function () {
          myLandbot.value = new Landbot.Livechat({
              configUrl: `https://storage.googleapis.com/landbot.pro/v3/${globalStore.siteSettings.landbot_id}/index.json`,
          });
  
          globalStore.setLandbotChatBox(myLandbot.value);
  
          // Start monitoring for chat opening
          intervalId = setInterval(findWelcome, 500);
      });
  
      s.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.mjs";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
  };
  
  onMounted(() => {
      if (globalStore.siteSettings.landbot_chat) {
          initLandbot();
      }
  });
  </script>